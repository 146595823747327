/**
 * Get config var with :
 * import { inject } from 'vue';
 * const xxx = inject('crmConfig.xxx');
 */
 const crmConfig = {
  apiUrl: 'https://crm.trouve-ton-apprentissage.fr:8877/',
  itemsPerPage: 12,
  minSearchLength: 2,
  version: '1.3.3',
  updateDate: '27/06/2024',
};

export default crmConfig;
