import { inject } from 'vue';
import { saveAs } from 'file-saver';
import ApiService from '@/services/api';
import HelperService from '@/services/helper';
import { ParamsModel } from '@/types/crm';

class FileService {
  download = async (filename: string, output: string): Promise<File|null> => {
    return await new Promise((resolve) => {
      ApiService.get('file/download/' + btoa(filename), {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        if (response.data) {
          const blob = new Blob([response.data]);
          resolve(saveAs(blob, output + this.getExtension(filename)));
        }
      }).catch((error) => {
        HelperService.notify(
          'error',
          error.response.data.message,
        );

        resolve(null);
      });
    });
  };

  downloadExport = async (model: string, params: ParamsModel|null): Promise<void> => {
    await ApiService.get(`${model}s/export`, {
      params: params,
      responseType: 'blob',
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'export.xlsx');
    })
    .catch((error) => {
      console.error('Erreur lors du téléchargement du fichier : ', error);
    });
  };

  getExtension = (filename: string): string => {
    const parts = filename.split('.');

    return '.' + parts[parts.length - 1];
  };

  getPathPublicFile = (path: string): string => {
    const apiUrl: string = inject('crmConfig.apiUrl') ?? '';

    return apiUrl.slice(0, -1) + path;
  };

  upload = (fileToUpload: string|Blob, type: string): Promise<string|null> => {
    const data = new FormData();
    data.append('file', fileToUpload);
    data.append('type', type);

    return new Promise((resolve) => {
      ApiService.post('file/upload',
        data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ).then((response) => {
        if (response.data.location) {
          resolve(response.data.location);
        }
      }).catch((error) => {
        HelperService.notify(
          'error',
          error.response.data.message,
        );

        resolve(null);
      });
    });
  };
}

export default new FileService();
