import Dialog from 'quasar/src/plugins/dialog/Dialog.js';import LocalStorage from 'quasar/src/plugins/storage/LocalStorage.js';import Notify from 'quasar/src/plugins/notify/Notify.js';;
import '../styles/quasar.scss';
import iconSet from 'quasar/icon-set/mdi-v7.js';
import lang from 'quasar/lang/fr.js';
import '@quasar/extras/mdi-v7/mdi-v7.css';

const quasarConfig = {
  config: {
  },
  plugins: {
    Dialog,
    LocalStorage,
    Notify,
  },
  lang: lang,
  iconSet: iconSet,
};

export default quasarConfig;
