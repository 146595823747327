import script from "./MenuComponent.vue?vue&type=script&setup=true&lang=ts"
export * from "./MenuComponent.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QExpansionItem,QItem,QItemSection,QIcon});
