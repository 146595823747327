import date from 'quasar/src/utils/date/date.js';import Notify from 'quasar/src/plugins/notify/Notify.js';;
import { EtatApprenantEnum, StateMembreEnum } from '@/enums/data';
import { EtatModel } from '@/types/entity';

class HelperService {
  calculateSalesRevenue = (amount: number|null, percentage: number|null): number|null => {
    return amount && percentage ? Math.round(((amount * percentage / 100) + Number.EPSILON) * 100 ) / 100 : null;
  };

  getBorderGroup = (index: number): string|null => {
    let classname: string|null = null;

    if (index > 0) {
      classname = 'q-btn--group_next';
    }

    return classname;
  };

  getColorState = (state: boolean|string): string|undefined => {
    switch(state) {
      case StateMembreEnum.VALIDE: {
        return 'primary';
      }
      case StateMembreEnum.DESACTIVE: {
        return 'red-10';
      }
      case StateMembreEnum.EN_ATTENTE: {
        return 'secondary';
      }
      case false: {
        return 'secondary';
      }
      case true: {
        return 'primary';
      }
    }
  };

  getFixedAmount = (value: number|null): number|null => {
    return value ? Number(Number(value * 100).toFixed(2)) : null;
  };

  getFormattedCurrencyNumber = (value: number|null): string|null => {
    return value !== null ? value.toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) : null;
  };

  getLastState = (etats: EtatModel[]): string|null => {
    if (etats && etats.length === 0) {
      return 'Création';
    }

    let lastState = null as EtatModel|null;

    Object.values(EtatApprenantEnum).forEach((etat) => {
      const matchingItem = etats.find((item) => item.statut === etat);

      if (matchingItem && !lastState) {
        lastState = matchingItem;
      }

      if (
        matchingItem && matchingItem.date
        && lastState && lastState.date
        && matchingItem.date >= lastState.date
      ) {
        lastState = matchingItem;
      }
    });

    return lastState ? lastState.statut : null;
  };

  getLastStateDate = (etats: EtatModel[], dernierEtat: string, dateCreation: string): string|null => {
    if (etats && etats.length === 0) {
      return date.formatDate(dateCreation, 'DD/MM/YYYY');
    }

    const matchingItem = etats.find((item) => item.statut === dernierEtat);

    return matchingItem && matchingItem.date ? date.formatDate(matchingItem.date, 'DD/MM/YYYY') : null;
  };

  getLegendsAction = (): Record<string, { color: string, name: string }> => {
    return {
      lvl1: { color: 'red-10', name: 'Échéance dépassée' },
      lvl2: { color: 'orange', name: 'Échéance fin de semaine' },
      lvl3: { color: 'blue-10', name: 'Échéance fin de semaine prochaine' },
      lvl4: { color: 'secondary', name: 'Échéance supérieure à fin de semaine prochaine' },
    };
  };

  notify = (name: string, message: string): void => {
    const type = {
      error: {
        color: 'red-10',
        icon: 'mdi-close-circle-outline',
      },
      success: {
        color: 'teal',
        icon: 'mdi-check-circle-outline',
      },
      warning: {
        color: 'warning',
        icon: 'mdi-alert-circle-outline',
      },
    };

    Notify.create({
      color: type[name].color,
      icon: type[name].icon,
      iconSize: '22px',
      message: message,
      timeout: 2000,
    });
  };
}

export default new HelperService();
