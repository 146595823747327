import { MenuModel } from '@/types/crm';

const menuConfig = {
  items: [
    {
      label: 'Tableau de bord',
      icon: 'view-dashboard-outline',
      route: 'tableau-de-bord',
      admin: false,
    },
    {
      label: 'Apprenants',
      icon: 'school-outline',
      route: 'apprenants',
      admin: false,
    },
    {
      label: 'Entreprises',
      icon: 'home-city-outline',
      route: 'entreprises',
      admin: false,
    },
    {
      label: 'Organismes de formation',
      icon: 'book-open-page-variant-outline',
      route: 'organismes',
      admin: false,
    },
    {
      label: 'Contrats',
      icon: 'currency-eur',
      route: 'contrats',
      admin: false,
    },
    {
      label: 'Paramètres',
      icon: 'cog-outline',
      admin: true,
      children: [
        {
          label: 'Listes',
          icon: 'format-list-checkbox',
          route: 'parametres-listes',
        },
        {
          label: 'Membres de l\'équipe',
          icon: 'account-supervisor-outline',
          route: 'parametres-membres',
        },
      ],
    },
  ] as MenuModel[],
};

export default menuConfig;
