import axios from 'axios';
import crmConfig from '@/config/crm';
import HelperService from '@/services/helper';
import TokenService from '@/services/token';
import UserService from '@/services/user';

const instance = axios.create({
  baseURL: crmConfig.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

const ERROR_MESSAGE = 'Une erreur inconnue est survenue, merci de réessayer';
let retry = false;

instance.interceptors.request.use(
  (config) => {
    const excludedPages = ['authentication', 'authentication/refresh', 'login'];
    const url = config.url ?? '';
    const jwt = TokenService.getJwt();

    config.headers = config.headers ?? {};

    if (config.method === 'patch') {
      config.headers['Content-Type']= 'application/merge-patch+json';
    }

    if (jwt && !excludedPages.includes(url)) {
      config.headers.Authorization = 'Bearer ' + jwt.token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    const httpResponse = [200, 201, 202];

    if (response.data.message && httpResponse.includes(response.status)) {
      HelperService.notify(
        'success',
        response.data.message,
      );
    }

    return response;
  },
  async (error) => {
    let errorMessage: string|null = null;

    if (!error.response || error.response.status >= 500) {
      errorMessage = ERROR_MESSAGE;
    } else if (error.response.status === 401 && !retry && TokenService.isTokenExpired()) {
      retry = true;

      const jwt = TokenService.getJwt();
      const { status, data } = await instance.post('authentication/refresh', { refreshToken: jwt.refreshToken });

      if (status === 200) {
        const { jwt, user } = TokenService.cast(data);

        TokenService.setJwt(jwt);
        UserService.setUser(user);

        retry = false;

        return instance(error.config);
      }
    } else if (error.response.status === 404) {
      errorMessage = 'Lien invalide';
    } else if ([ 422, 423 ].includes(error.response.status)) {
        // The error message is processed in the form
        errorMessage = null;
    } else {
      errorMessage = error.response.data.message || ERROR_MESSAGE;
    }

    if (errorMessage) {
      HelperService.notify(
        'error',
        errorMessage,
      );
    }

    retry = false;

    return Promise.reject(error);
  },
);

export default instance;
