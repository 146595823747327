import LocalStorage from 'quasar/src/plugins/storage/LocalStorage.js';;
import { RoleEnum } from '@/enums/param';
import ApiService from '@/services/api';
import UserService from '@/services/user';

class LockService {
  add = async (url: string|null): Promise<any> => {
    const response = await ApiService.get(url + '/lock-add');

    LocalStorage.set('lock', url);

    return response.data;
  };

  canUnlock = (): boolean => {
    if (UserService.hasRole(RoleEnum.ADMIN)) {
     return true;
    }

    return false;
  };

  remove = async (): Promise<void> => {
    if (LocalStorage.has('lock')) {
      await ApiService.get(LocalStorage.getItem('lock') + '/lock-remove')
      .then(() => {
        LocalStorage.remove('lock');
      })
      .catch ((error) => {
        console.error(error);
      });
    }
  };
}

export default new LockService();
