import { AxiosResponse } from 'axios';
import { Commit } from 'vuex';
import ApiService from '@/services/api';
import TokenService from '@/services/token';
import UserService from '@/services/user';
import { AuthenticationState, User } from '@/types/authentication';

const authentication = {
  namespaced: true,

  state: {
    user: UserService.getUser(),
  },

  actions: {
    login: async ({ commit }: { commit: Commit }, payload: User): Promise<AxiosResponse<any, any>> => {
      try {
        const response = await ApiService.post('authentication', payload);
        const { jwt, user } = TokenService.cast(response.data);

        TokenService.setJwt(jwt);
        UserService.setUser(user);
        commit('logUser', user);

        return await Promise.resolve(response);
      } catch (error) {
        commit('logUser', null);

        return await Promise.reject(error);
      }
    },
    logout: ({ commit }: { commit: Commit }): void => {
      TokenService.removeJwt();
      UserService.removeUser();
      commit('logOut');
    },
  },

  mutations: {
    logUser: (state: AuthenticationState, user: User): void => {
      state.user = user;
    },
    logOut: (state: AuthenticationState): void => {
      state.user = null;
    },
  },
};

export default authentication;
