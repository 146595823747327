<template>
  <q-layout
    class="bg-grey-1"
    view="hHh Lpr lff"
  >
    <q-header
      v-if="user && isNotPublicPage"
      bordered
      class="q-header--title q-py-xs"
    >
      <q-toolbar>
        <q-avatar
          class="q-ml-sm"
          size="xl"
        >
          <q-img
            color="red"
            src="@/assets/logo.png"
          />
        </q-avatar>
        <q-toolbar-title>
          <div>
            <span class="text-h5 text-primary text-weight-bold">CRM</span>
            <span class="text-h6 text-accent">TTA</span>
          </div>
        </q-toolbar-title>
        <q-space />
        <q-btn-dropdown
          v-model="accountDropdown"
          content-class="bg-white no-shadow"
          content-style="border: 1px solid #e0e0e0; margin-top: 5px !important;"
          unelevated
        >
          <template #label>
            <q-avatar
              v-if="user.photo !== null"
              class="q-mr-sm"
              size="42px"
            >
              <img :src="FileService.getPathPublicFile(user.photo)">
            </q-avatar>
            <q-avatar
              v-else
              class="q-mr-sm"
              color="white"
              icon="mdi-account"
              size="42px"
              text-color="primary"
            />
            {{ user.nomComplet }}
          </template>

          <q-list>
            <q-item>
              <q-item-section avatar>
                <q-icon
                  color="primary"
                  name="mdi-account-outline"
                />
              </q-item-section>

              <q-item-section>
                <q-item-label>{{ user.fonction ?? 'Fonction non définie' }}</q-item-label>
                <q-item-label
                  caption
                  class="text-accent"
                  lines="1"
                >
                  {{ user.site }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-separator />

            <div v-if="user.multisite && optionsSite.length > 1">
              <q-item
                clickable
                @click="accountDropdown = false; dialogChangeSite = true;"
              >
                <q-item-section avatar>
                  <q-icon
                    color="primary"
                    name="mdi-account-sync-outline"
                  />
                </q-item-section>

                <q-item-section>Changer de site</q-item-section>
              </q-item>

              <q-separator />
            </div>

            <q-item
              clickable
              @click="accountDropdown = false; dialogResetAccount = true;"
            >
              <q-item-section avatar>
                <q-icon
                  color="primary"
                  name="mdi-account-lock-outline"
                />
              </q-item-section>

              <q-item-section>Réinitialiser mon compte</q-item-section>
            </q-item>

            <q-separator />

            <q-item
              clickable
              @click="logout"
            >
              <q-item-section avatar>
                <q-icon
                  color="primary"
                  name="mdi-account-arrow-right-outline"
                />
              </q-item-section>

              <q-item-section>Se déconnecter</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-toolbar>
    </q-header>

    <q-dialog
      v-model="dialogChangeSite"
      persistent
    >
      <q-card
        class="q-pa-xs"
        flat
      >
        <q-card-section>
          <div class="text-primary text-subtitle1">
            Changer de site
          </div>
        </q-card-section>
        <q-card-section class="q-mb-xs q-pt-none q-pb-md">
          <q-select
            v-model="site"
            class="full-width"
            dense
            label="Site"
            :options="user?.multisite?.filter((val) => val !== user?.site && optionsSite.includes(val)).sort()"
            options-dense
            outlined
          />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            v-close-popup
            class="q-mx-sm q-mb-sm"
            color="secondary"
            label="Annuler"
            style="padding: 4px 16px;"
            unelevated
            @click="site = null"
          />
          <q-btn
            v-close-popup
            class="q-mr-sm q-mb-sm"
            color="primary"
            label="Valider"
            style="padding: 4px 16px;"
            unelevated
            @click="changeSite"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog
      v-model="dialogResetAccount"
      persistent
    >
      <q-card
        class="q-pa-xs"
        flat
      >
        <q-card-section>
          <div class="text-primary text-subtitle1">
            Réinitialiser le compte
          </div>
        </q-card-section>
        <q-card-section class="q-pt-none q-pb-sm">
          <p class="q-mb-sm">
            Êtes-vous sûr.e de vouloir réinitialiser votre compte ?
          </p>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            v-close-popup
            class="q-mr-sm q-mb-sm"
            color="secondary"
            label="Annuler"
            style="padding: 4px 16px;"
            unelevated
          />
          <q-btn
            v-close-popup
            class="q-mr-sm q-mb-sm"
            color="primary"
            label="Valider"
            style="padding: 4px 16px;"
            unelevated
            @click="resetAccount"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-drawer
      v-if="user && isNotPublicPage"
      v-model="menuDrawer"
      bordered
      :mini="miniState"
      mini-to-overlay
      overlay
      persistent
      side="left"
      :width="240"
      @mouseover="miniState = false"
      @mouseout="miniState = true"
    >
      <MenuComponent :mini-state="miniState" />
      <q-btn
        class="absolute fixed-bottom full-width"
        color="secondary"
        dense
        :label="miniState ? inject('crmConfig.version') : `Version ${inject('crmConfig.version')} - ${inject('crmConfig.updateDate')}`"
        no-caps
        text-color="white"
        unelevated
      />
    </q-drawer>

    <q-page-container style="margin-left: 57px">
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import MenuComponent from '@/components/MenuComponent.vue';
import { SiteEnum } from '@/enums/data';
import ApiService from '@/services/api';
import FileService from '@/services/file';
import LockService from '@/services/lock';
import UserService from '@/services/user';
import { User } from '@/types/authentication';

const router = useRouter();
const store = useStore();

const accountDropdown = ref(false);
const dialogChangeSite = ref(false);
const dialogResetAccount = ref(false);
const menuDrawer = ref(true);
const miniState = ref(true);
const optionsSite = ref<string[]>([]);
const site = ref<string|null>(null);
const user = ref<User|null>(null);

const changeSite = async (): Promise<void> => {
  await ApiService.patch('membres/' + user.value?.id + '/change-site', { site: site.value })
  .then(() => {
    LockService.remove();
    UserService.refresh();
  })
  .catch((error) => {
    console.error(error);
  });
};

const isNotPublicPage = computed(() => {
  const currentRoute = router.currentRoute.value.name ? router.currentRoute.value.name.toString() : '';
  const publicPages = ['login', 'reinitialisation-membre', 'validation-membre'];

  return !publicPages.includes(currentRoute);
});

const logout = (): void => {
  accountDropdown.value = false;

  LockService.remove();

  store.dispatch('authentication/logout');
  router.push({ name: 'login' });
};

const resetAccount = async (): Promise<void> => {
  await ApiService.post('membres/reinitialisation', {
    email: user.value?.email,
  })
  .then(() => {
    LockService.remove();
    router.push({ name: 'login' });
  })
  .catch((error) => {
    console.error(error);
  });
};

watchEffect((): void => {
  user.value = store.state.authentication.user;
});

onMounted((): void => {
  Object.values(SiteEnum).forEach((item) => {
    optionsSite.value.push(item);
  });
});
</script>
