import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { BreadcrumbsEnum } from '@/enums/param';
import UserService from '@/services/user';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'tableau-de-bord',
    component: () => import('@/views/TableauDeBordView.vue'),
    meta: { menu: 'tableau-de-bord' },
  },
  // Apprenants
  {
    path: '/apprenant/:id?',
    component: () => import('@/views/Apprenant/ApprenantView.vue'),
    meta: { menu: 'apprenants' },
    props: true,
    children: [
      {
        path: 'fiche',
        name: 'apprenant-information',
        component: () => import('@/components/Information/Apprenant/ApprenantInformation.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.FICHE, tabs: true },
      },
      {
        path: 'formulaire',
        name: 'apprenant-form',
        component: () => import('@/components/Form/Apprenant/ApprenantForm.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.FORMULAIRE, lock: true },
      },
      {
        path: 'search',
        name: 'apprenant-search',
        component: () => import('@/components/Form/Apprenant/SearchForm.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.RECHERCHE },
      },
      {
        path: 'synthese',
        name: 'apprenant-synthese',
        component: () => import('@/views/Apprenant/SyntheseView.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.SYNTHESE, tabs: true },
      },
      {
        path: 'contrats',
        name: 'apprenant-contrats',
        component: () => import('@/components/Table/Contrat/ContratTable.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.CONTRATS, tabs: true },
      },
      {
        path: 'actions',
        name: 'apprenant-actions',
        component: () => import('@/views/ActionView.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.ACTIONS, tabs: true },
      },
    ],
  },
  {
    path: '/apprenants',
    name: 'apprenants',
    component: () => import('@/views/Apprenant/ApprenantsView.vue'),
    meta: { breadcrumb: BreadcrumbsEnum.LISTE, menu: 'apprenants' },
  },
  // Contrats
  {
    path: '/contrat/:id?',
    component: () => import('@/views/Contrat/ContratView.vue'),
    meta: { menu: 'contrats' },
    props: true,
    children: [
      {
        path: 'fiche',
        name: 'contrat-information',
        component: () => import('@/components/Information/Contrat/ContratInformation.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.FICHE },
      },
      {
        path: 'formulaire',
        name: 'contrat-form',
        component: () => import('@/components/Form/Contrat/ContratForm.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.FORMULAIRE },
      },
    ],
  },
  {
    path: '/contrats',
    name: 'contrats',
    component: () => import('@/views/Contrat/ContratsView.vue'),
    meta: { breadcrumb: BreadcrumbsEnum.LISTE, menu: 'contrats' },
  },
  // Entreprises
  {
    path: '/entreprise/:id?',
    component: () => import('@/views/Entreprise/EntrepriseView.vue'),
    meta: { menu: 'entreprises' },
    props: true,
    children: [
      {
        path: 'fiche',
        name: 'entreprise-information',
        component: () => import('@/components/Information/Entreprise/EntrepriseInformation.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.FICHE, tabs: true },
      },
      {
        path: 'formulaire',
        name: 'entreprise-form',
        component: () => import('@/components/Form/Entreprise/EntrepriseForm.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.FORMULAIRE },
      },
      {
        path: 'search',
        name: 'entreprise-search',
        component: () => import('@/components/Form/Entreprise/SearchForm.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.RECHERCHE },
      },
      {
        path: 'formations',
        name: 'entreprise-formations',
        component: () => import('@/views/Entreprise/FormationView.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.FORMATIONS, tabs: true },
      },
      {
        path: 'postes',
        name: 'entreprise-postes',
        component: () => import('@/views/Entreprise/PosteView.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.POSTES, tabs: true },
      },
      {
        path: 'contrats',
        name: 'entreprise-contrats',
        component: () => import('@/components/Table/Contrat/ContratTable.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.CONTRATS, tabs: true },
      },
      {
        path: 'actions',
        name: 'entreprise-actions',
        component: () => import('@/views/ActionView.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.ACTIONS, tabs: true },
      },
    ],
  },
  {
    path: '/entreprises',
    name: 'entreprises',
    component: () => import('@/views/Entreprise/EntreprisesView.vue'),
    meta: { breadcrumb: BreadcrumbsEnum.LISTE, menu: 'entreprises' },
  },
  // Organismes
  {
    path: '/organisme/:id?',
    component: () => import('@/views/Organisme/OrganismeView.vue'),
    meta: { menu: 'organismes' },
    props: true,
    children: [
      {
        path: 'fiche',
        name: 'organisme-information',
        component: () => import('@/components/Information/Organisme/OrganismeInformation.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.FICHE, tabs: true },
      },
      {
        path: 'formulaire',
        name: 'organisme-form',
        component: () => import('@/components/Form/Organisme/OrganismeForm.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.FORMULAIRE },
      },
      {
        path: 'search',
        name: 'organisme-search',
        component: () => import('@/components/Form/Organisme/SearchForm.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.RECHERCHE },
      },
      {
        path: 'formations',
        name: 'organisme-formations',
        component: () => import('@/views/Organisme/FormationView.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.FORMATIONS, tabs: true },
      },
      {
        path: 'contrats',
        name: 'organisme-contrats',
        component: () => import('@/components/Table/Contrat/ContratTable.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.CONTRATS, tabs: true },
      },
      {
        path: 'actions',
        name: 'organisme-actions',
        component: () => import('@/views/ActionView.vue'),
        meta: { breadcrumb: BreadcrumbsEnum.ACTIONS, tabs: true },
      },
    ],
  },
  {
    path: '/organismes',
    name: 'organismes',
    component: () => import('@/views/Organisme/OrganismesView.vue'),
    meta: { breadcrumb: BreadcrumbsEnum.LISTE, menu: 'organismes' },
  },
  // Paramètres
  {
    path: '/parametres/liste/:id?/formulaire',
    name: 'parametres-liste-form',
    component: () => import('@/views/Parametres/ListeView.vue'),
    props: true,
    meta: { breadcrumb: BreadcrumbsEnum.FORMULAIRE, menu: 'parametres-listes' },
  },
  {
    path: '/parametres/listes',
    name: 'parametres-listes',
    component: () => import('@/views/Parametres/ListesView.vue'),
    meta: { breadcrumb: BreadcrumbsEnum.LISTE, menu: 'parametres-listes' },
  },
  {
    path: '/parametres/membre/:id?/formulaire',
    name: 'parametres-membre-form',
    component: () => import('@/views/Parametres/MembreView.vue'),
    props: true,
    meta: { breadcrumb: BreadcrumbsEnum.FORMULAIRE, menu: 'parametres-membres' },
  },
  {
    path: '/parametres/membres',
    name: 'parametres-membres',
    component: () => import('@/views/Parametres/MembresView.vue'),
    meta: { breadcrumb: BreadcrumbsEnum.LISTE, menu: 'parametres-membres' },
  },
  // Autres
  {
    path: '/membres/:id/validation',
    name: 'validation-membre',
    component: () => import('@/views/ValidationMembreView.vue'),
  },
  {
    path: '/membres/reinitialisation',
    name: 'reinitialisation-membre',
    component: () => import('@/views/ReinitialisationMembreView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _from, next): void => {
  const currentRoute = to.name ? to.name.toString() : '';
  const publicPages = ['login', 'reinitialisation-membre', 'validation-membre'];
  const authenticationRequired = !publicPages.includes(currentRoute);
  const isAuthenticated = UserService.isAuthenticated();

  // Trying to access a restricted page + not authenticated redirect to login page
  if (authenticationRequired && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router;
