import ApiService from '@/services/api';
import TokenService from '@/services/token';
import { Jwt, User } from '@/types/authentication';

class UserService {
  getUser = (): User|null => {
    return JSON.parse(localStorage.getItem('user') || JSON.stringify(null));
  };

  removeUser = (): void => {
    localStorage.removeItem('user');
  };

  setUser = (user: User): void => {
    localStorage.setItem('user', JSON.stringify(user));
  };

  isAuthenticated = (): boolean => {
    return this.getUser() ? true : false;
  };

  isConnectedUser = (id: string): boolean => {
    return this.getUser()?.id === id;
  };

  hasRole = (role: string): boolean => {
    const roles = this.getUser() ? this.getUser()?.roles : [];

    return roles ? roles.includes(role) : false;
  };

  refresh = async (): Promise<void> => {
    const jwt: Jwt = TokenService.getJwt();

    await ApiService.post('authentication/refresh', { refreshToken: jwt.refreshToken })
    .then((response) => {
      jwt.token = response.data.token;
      jwt.refreshToken = response.data.refreshToken;
      TokenService.setJwt(jwt);

      const { user } = TokenService.cast(jwt);
      this.setUser(user);

      window.location.reload();
    })
    .catch ((error) => {
      console.error(error);
    });
  };
}

export default new UserService();
