import { createApp } from 'vue';
import App from './App.vue';
import crmConfig from './config/crm';
import router from './router';
import store from './store';

// Quasar
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarConfig from './config/quasar';

const app = createApp(App);

app
  .use(router)
  .use(store)
  .use(Quasar, quasarConfig)
;

// Provide crm configuration
for (const param in crmConfig) {
  app.provide('crmConfig.' + param, crmConfig[param]);
}

// Global error handler
app.config.errorHandler = (err, _vm, info) => {
  console.error('Vue Error:', err, info);
};

app.mount('#app');
