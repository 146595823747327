<template>
  <div
    style="max-width: 350px"
  >
    <q-list>
      <div
        v-for="item in menuConfig.items"
        :key="item.label"
      >
        <div
          v-if="
            !item.admin ||
              (
                item.admin &&
                UserService.hasRole(RoleEnum.ADMIN)
              )
          "
        >
          <q-expansion-item
            v-if="item.children"
            ref="expansionItem"
            :model-value="shownExpansionItem === item.label"
            group="menu"
            :header-class="(miniState || hiddenExpansionItem === item.label) && shownExpansionItem === item.label ? 'q-item--active' : ''"
            @show="setHiddenExpansionItem(item.label, false);"
            @hide="setHiddenExpansionItem(item.label, true);"
          >
            <template #header>
              <q-item-section avatar>
                <q-icon
                  color="primary"
                  :name="'mdi-' + item.icon"
                />
              </q-item-section>

              <q-item-section>
                {{ item.label }}
              </q-item-section>
            </template>
            <q-item
              v-for="child in item.children"
              :key="child.label"
              :active="child.route === route.meta.menu"
              class="q-pl-lg"
              clickable
              @click="shownExpansionItem = item.label; router.push({ name: child.route })"
            >
              <q-item-section avatar>
                <q-icon
                  color="primary"
                  :name="'mdi-' + child.icon"
                />
              </q-item-section>
              <q-item-section>
                {{ child.label }}
              </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-item
            v-else
            :active="item.route === route.meta.menu"
            clickable
            @click="shownExpansionItem = item.label; itemOnly(item.route)"
          >
            <q-item-section
              avatar
            >
              <q-icon
                color="primary"
                :name="'mdi-' + item.icon"
              />
            </q-item-section>
            <q-item-section>
              {{ item.label }}
            </q-item-section>
          </q-item>
        </div>
      </div>
    </q-list>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import menuConfig from '@/config/menu';
import { RoleEnum } from '@/enums/param';
import UserService from '@/services/user';

const shownExpansionItem = ref('');
const hiddenExpansionItem = ref('');
const expansionItem = ref(Object);
const route = useRoute();
const router = useRouter();

defineProps<{
  miniState: boolean,
}>();

const itemOnly = (route: any): void => {
  shownExpansionItem.value = '';
  hiddenExpansionItem.value = '';

  if (expansionItem.value.length > 1) {
    for (let i = 0; i < expansionItem.value.length; i++) {
      expansionItem.value[i].hide();
    }
  }

  router.push({ name: route });
};

const setHiddenExpansionItem = (label: string, hide: boolean): void => {
  if (label === shownExpansionItem.value) {
    hiddenExpansionItem.value = hide ? shownExpansionItem.value : '';
  }
};

onMounted(async (): Promise<void> => {
  await router.isReady();

  menuConfig.items.forEach((item) => {
    if (item.children) {
      item.children.forEach((child) => {
        if (child.route === route.name) {
          shownExpansionItem.value = item.label;
        }
      });
    }
  });
});
</script>
