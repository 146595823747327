// Button / Button group
export const PermisApprenantEnum = Object.freeze({
  PERMIS: 'Permis',
  EN_COURS: 'En cours',
});

export const StateMembreEnum = Object.freeze({
  DESACTIVE: 'Désactivé',
  EN_ATTENTE: 'En attente',
  VALIDE: 'Validé',
});

export const TypeFormationEnum = Object.freeze({
  ALTERNANCE: 'Alternance',
  INITIAL: 'Initial',
});

export const TypeOrganismeEnum = Object.freeze({
  PRIVE: 'Privé',
  PUBLIC: 'Public',
  SOUS_MANDAT: 'Sous mandat',
});

// Checkbox
export const EtatApprenantEnum = Object.freeze({
  PROSPECT: 'Prospect',
  ADMIS: 'Admis',
  PLACE: 'Placé',
  REFUSE: 'Refusé',
  ANNULE: 'Annulé',
  CLIENT: 'Client',
});

export const EtatContratEnum = Object.freeze({
  TRANSMIS: 'Transmis',
  SIGNE: 'Signé',
  EN_COURS: 'En cours',
  TERMINE: 'Terminé',
  ROMPU: 'Rompu',
});

export const PieceApprenantEnum = Object.freeze({
  CONTRAT: 'Contrat',
  CONDITIONS_GENERALES: 'Conditions générales',
  CURRICULUM_VITAE: 'Curriculum Vitae',
  LETTRE_MOTIVATION: 'Lettre de motivation',
  LETTRE_RECOMMANDATION: 'Lettre de recommandation',
  AUTRE: 'Autre',
});

export const TypeRelationOrganismeEnum = Object.freeze({
  PROSPECT: 'Prospect',
  CLIENT_COURS: 'Client cours',
  CLIENT_CONTRAT: 'Client contrat',
  ANCIEN_CLIENT: 'Ancien client',
  AUTRE_PARTENAIRE: 'Autre partenaire RH',
  LISTE_NOIRE: 'Liste noire',
});

export const StatutEntrepriseEnum = Object.freeze({
  SANS_POTENTIEL: 'Sans potentiel',
  JAMAIS_CONTACTE: 'Jamais contacté',
  PROSPECT_FROID: 'Prospect froid',
  PROSPECT_CHAUD: 'Prospect chaud',
  MANDAT_EN_COURS: 'Mandat en cours',
  CLIENT: 'Client',
  ANCIEN_CLIENT: 'Ancien client',
  LISTE_NOIRE: 'Liste noire',
});

// List
export const CiviliteContactEnum = Object.freeze({
  MADAME: 'Madame',
  MONSIEUR: 'Monsieur',
});

export const DiplomeApprenantEnum = Object.freeze({
  DIPLOME_EN_COURS: 'Diplôme en cours',
  DERNIER_DIPLOME_OBTENU: 'Dernier diplôme obtenu',
  AUTRE_DIPLOME: 'Autre diplôme',
});

export const EtatPosteEnum = Object.freeze({
  ACTIF: 'Actif',
  ANNULE: 'Annulé',
  PERDU: 'Perdu',
  POURVU: 'Pourvu',
  ROMPU: 'Rompu',
});

export const EvaluationEnum = Object.freeze({
  PONCTUALITE: 'Ponctualité',
  DOCUMENTS: 'Documents',
  TENUE: 'Tenue',
  HYGIENE: 'Hygiène',
  DYNAMISME: 'Dynamisme',
  CURIOSITE: 'Curiosité',
  MAITRISE_PROJET: 'Maîtrise projet',
  VOCABULAIRE: 'Vocabulaire',
  REACTIVITE: 'Réactivité',
});

export const LangueApprenantEnum = Object.freeze({
  ANGLAIS: 'Anglais',
  ALLEMAND: 'Allemand',
});

export const ListeEnum = Object.freeze({
  FORMATION: 'Formation',
  FORMULE: 'Formule',
  LANGUE: 'Langue',
  LIEU_ENTRETIEN: 'Lieu d\'entretien',
  SOURCE_COMMUNICATION: 'Source de communication',
  TYPE_ACTION: 'Type d\'action',
  TYPE_STRUCTURE: 'Type de structure',
});

export const MoyenPaiementEnum = Object.freeze({
  CHEQUE: 'Chèque',
  ESPECES: 'Espèces',
  VIREMENT: 'Virement',
});

export const NiveauEnum = Object.freeze({
  EXCELLENT: 'Excellent',
  BON: 'Bon',
  INTERMEDIAIRE: 'Intermédiaire',
  BASE: 'Base',
  NOTIONS: 'Notions',
  AUCUN: 'Aucun',
});

export const SiteEnum = Object.freeze({
  BAS_RHIN: 'Bas-Rhin',
});

export const TypeContactEnum = Object.freeze({
  COMMERCE: 'Commerce',
  DIRECTION: 'Direction',
  PEDAGOGIE: 'Pédagogie',
});

export const TypeContratPosteEnum = Object.freeze({
  APPRENTISSAGE: 'Apprentissage',
  CONTRAT_PRO: 'Contrat pro',
  STAGE_COURT: 'Stage court',
  STAGE_LONG: 'Stage long',
});
