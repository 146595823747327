export const NoDeleteItemsEnum = [
  '01HCQQF1V42V433YN5T8ME0F36', // Langue - Allemand
  '01HCQQE7XZKEYRCZKVRR8KANVK', // Langue - Anglais
];

export const BreadcrumbsEnum = Object.freeze({
  ACTIONS: 'Actions',
  AJOUTER: 'Ajouter',
  CONTRATS: 'Contrats',
  FICHE: 'Fiche',
  LISTE: 'Liste',
  LISTES: 'Listes',
  FORMATIONS: 'Formations',
  FORMULAIRE: 'Formulaire',
  MEMBRES: 'Membres',
  MODIFIER: 'Modifier',
  POSTES: 'Postes',
  RECHERCHE: 'Recherche',
  SYNTHESE: 'Synthèse',
});

export const FileTypeEnum = Object.freeze({
  DOCUMENT: 'document',
  PHOTO: 'photo',
});

export const RoleEnum = Object.freeze({
  ADMIN: 'ROLE_ADMIN',
});

export const TabEnum = Object.freeze({
  ACTIONS: 'Actions',
  CONTRATS: 'Contrats',
  FICHE: 'Fiche',
  FORMATIONS: 'Formations',
  POSTES: 'Postes',
  SYNTHESE: 'Synthèse',
});
