import { Jwt, User } from '@/types/authentication';

class TokenService {
  getJwt = (): Jwt => {
    return JSON.parse(localStorage.getItem('jwt') || JSON.stringify(null));
  };

  setJwt = (jwt: Jwt): void => {
    localStorage.setItem('jwt', JSON.stringify(jwt));
  };

  removeJwt = (): void => {
    localStorage.removeItem('jwt');
  };

  cast = (jwtToCast: Jwt) => {
    const decryptedToken = this.decrypt(jwtToCast.token);

    const jwt: Jwt = {
      token: jwtToCast.token,
      refreshToken: jwtToCast.refreshToken,
      exp: decryptedToken.exp,
    };

    // Remove unnecessary items
    delete decryptedToken.exp;
    delete decryptedToken.iat;

    const user: User = decryptedToken;

    return { jwt, user };
  };

  decrypt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  };

  isTokenExpired = (): boolean => {
    const jwt = this.getJwt();

    return jwt && (Date.now() >= jwt.exp * 1000) ? true : false;
  };
}

export default new TokenService();
